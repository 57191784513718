import { zodResolver } from '@hookform/resolvers/zod';
import { useRouter } from 'next/router';
import React, { UIEventHandler, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { Button } from '~/components/buttons/Button';
import { Check } from '~/components/data-entries/Check';
import { Input } from '~/components/data-entries/Input';
import { Label } from '~/components/data-entries/Label';
import { SectionBase } from '~/components/domains/SectionBase';
import { ErrorMessages } from '~/components/feedbacks/ErrorMessages';
import { stepperSteps } from '~/constants/stepperSteps';
import { termsAndConditions } from '~/constants/termsAndConditions';
import { fireGAEvent } from '~/libs/fireGAEvent';
import { alignCss } from '~/styles/alignCss';
import * as styles from './index.styles';
import { validateEmail } from '~/utils/validateEmail';

export type Props = {
  onClickRegister: (formData: Omit<FormValues, 'acceptedTerms' | 'selfEmployed'>) => void;
  sending: boolean;
};

// NOTE: https://www.notion.so/timee/or-validate-7b3de6387e5645d08a74b51aaade3c3c#53e9743891de4720bc81049f09805937
const schema = z.object({
  selfEmployed: z.boolean({ required_error: '事業形態を選択してください。' }),
  email: z
    .string()
    .trim()
    .min(1, { message: 'メールアドレスを入力してください' })
    .max(255, { message: '255文字以下で入力してください' })
    .refine(validateEmail, { message: 'メールアドレスを正しい形式で入力してください' }),
  acceptedTerms: z.literal<boolean>(true, {
    errorMap: () => ({
      message: 'アカウント開設には上記の各事項に同意が必要です。',
    }),
  }),
});

type FormValues = z.infer<typeof schema>;

const TACView = () => {
  const [showOverlay, setShowOverlay] = useState(true);

  const closeOverlay = useCallback(() => {
    setShowOverlay(false);
  }, []);

  const handleScroll: UIEventHandler = (event) => {
    const scrollTop = event.currentTarget.scrollTop;
    if (scrollTop > 600) setShowOverlay(false);
  };

  return (
    <div css={styles.tac.outerContainer}>
      <div css={styles.tac.container} onScroll={handleScroll}>
        {termsAndConditions.map((item, idx) => (
          <div key={idx}>
            <div css={styles.tac.title}>{item.title}</div>
            <div>{item.node}</div>
          </div>
        ))}
      </div>

      {showOverlay && (
        <div onClick={closeOverlay} css={styles.tac.overlay.container}>
          <div css={styles.tac.overlay.arrowsContainer}>
            <div css={styles.tac.overlay.arrowContainer}>
              <div css={styles.tac.overlay.arrow} />
            </div>
            <div css={styles.tac.overlay.arrowContainer}>
              <div css={styles.tac.overlay.arrow} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const TACAndInputEmailSection: React.FC<Props> = (props: Props) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
  });

  const onSubmitHandler = useCallback(
    handleSubmit((d) => {
      if (props.sending) return;
      props.onClickRegister({
        email: d.email,
      });
    }),
    [props.sending]
  );
  const router = useRouter();

  useEffect(() => {
    fireGAEvent.customEvents.viewTACAndInputEmailSection(router.asPath);
  }, []);

  return (
    <form onSubmit={onSubmitHandler}>
      <SectionBase
        steps={stepperSteps}
        currentStep={0}
        title="メールアドレスの登録"
        sections={[
          {
            title: '申し込みの前に以下を確認してください。',
            node: (
              <>
                <TACView />
                <div css={alignCss({ mt: 1 })}></div>
                <Controller
                  control={control}
                  name="acceptedTerms"
                  defaultValue={false}
                  render={({ field, fieldState }) => (
                    <Label errorMessage={fieldState.error?.message} htmlFor="acceptedTerms">
                      <Check
                        id="acceptedTerms"
                        label="上記の各事項に同意する"
                        checked={field.value}
                        error={!!fieldState.error}
                        onChange={(newValue) => {
                          field.onChange(newValue);
                        }}
                      />
                    </Label>
                  )}
                />
              </>
            ),
          },
          {
            node: (
              <Controller
                control={control}
                name="selfEmployed"
                defaultValue={undefined}
                render={({ field }) => (
                  <>
                    <Label label="事業形態" errorMessage={errors?.selfEmployed?.message} labelBold>
                      <div css={alignCss({ mb: 0.5 })}>
                        <input
                          type="radio"
                          id="corporation"
                          checked={field.value === false}
                          onChange={() => field.onChange(false)}
                          css={alignCss({ mr: 1 })}
                        />
                        <label htmlFor="corporation">法人</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="self-employed"
                          checked={field.value === true}
                          onChange={() => field.onChange(true)}
                          css={alignCss({ mr: 1 })}
                        />
                        <label htmlFor="self-employed">個人事業主</label>
                      </div>
                    </Label>
                    <div
                      css={[
                        styles.forWorkerNote({ error: !!errors.selfEmployed }),
                        alignCss({ mt: 0.5 }),
                      ]}
                    >
                      {errors.selfEmployed ? '' : '＊'}
                      お仕事を探している方は
                      <a
                        href="https://timee.co.jp/"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                          fireGAEvent.customEvents.clickWorkerAppLink();
                        }}
                      >
                        アプリ
                      </a>
                      をご利用ください。
                    </div>
                  </>
                )}
              />
            ),
          },
          {
            node: (
              <Label
                label="登録するメールアドレス"
                htmlFor="email"
                errorMessage={errors?.email?.message}
                labelBold
              >
                <Input
                  type="text"
                  id="email"
                  error={!!errors?.email?.message}
                  {...register('email')}
                />
              </Label>
            ),
          },
          {
            node: (
              <>
                <Button
                  buttonHeight={48}
                  fontSize="m"
                  display="block"
                  design="primary"
                  type="submit"
                  loading={props.sending}
                >
                  アカウントの開設をはじめる
                </Button>
                <ErrorMessages.WithStore />
              </>
            ),
          },
        ]}
      />
    </form>
  );
};
